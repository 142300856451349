// export default IndustryWeb;
import { Col, Nav, Row, Tab } from "react-bootstrap";
import React, { useState } from "react";
import Image from "next/image";
import { toWebp } from "../../../utils/common";
import styles from "./style.module.scss";
import Link from "next/link";
// import TextExpand from "./TextExpand";
import TruncatedText from "./TruncatedText";
const IndustryWeb = ({ data }) => {
	const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;

	const [activeIndex, setActiveIndex] = useState(0);
	const [activeEleIndex, setActiveEleIndex] = useState(0);
	const [isTruncated, setIsTruncated] = useState(false);

	const handleHover = (eleIndex) => {
		setActiveEleIndex(eleIndex);
	};

	return (
		<div className={styles["industry-md-none"]}>
			<Tab.Container id="left-tabs-example" defaultActiveKey={0}>
				<Row className="justify-content-center">
					<Col lg={11} xl={10}>
						<Nav
							variant="pills"
							className={styles["industry-tab-heading"]}
						>
							{data?.Keypoint &&
								data?.Keypoint.map((key3, i) => {
									return (
										<Nav.Item
											className={styles["nav-item"]}
											key={`${i}_k`}
										>
											<Nav.Link
												className={`${
													styles["nav-link"]
												} ${
													activeIndex === i
														? styles["active"]
														: ""
												}`}
												eventKey={i}
												onClick={() => {
													setActiveIndex(i);
												}}
											>
												{key3.title}
											</Nav.Link>
										</Nav.Item>
									);
								})}
						</Nav>
					</Col>
				</Row>

				<Tab.Content className={styles["indutry-tab-content"]}>
					{data?.Keypoint &&
						data?.Keypoint.map((key, i) => {
							return (
								<React.Fragment key={`${i}_P`}>
									{key?.points &&
										key?.points.map((point, ind) => {
											return (
												<Tab.Pane
													eventKey={i}
													key={`${ind}_j`}
												>
													<Row>
														<Col lg={12} md={12}>
															<div
																className={`${
																	styles[
																		"industry-right-content"
																	]
																} ${
																	activeEleIndex ===
																	ind
																		? styles[
																				"active"
																		  ]
																		: ""
																}`}
																onMouseEnter={() =>
																	handleHover(
																		ind
																	)
																}
															>
																<Row>
																	<Col
																		lg={3}
																		md={8}
																	>
																		<div
																			className={
																				styles[
																					"industry-lt-content"
																				]
																			}
																		>
																			<h4>
																				{
																					point.title
																				}
																			</h4>
																			{point
																				?.button
																				?.label &&
																				point
																					?.button
																					?.href && (
																					<a
																						href={
																							point
																								?.button
																								?.href
																						}
																						className={`btn ${styles["view-more-btn"]}`}
																					>
																						{
																							point
																								?.button
																								?.label
																						}

																						<Image
																							src="/images/home/skype-right-arrow.svg"
																							alt="icon"
																							height={
																								20
																							}
																							width={
																								20
																							}
																						/>
																					</a>
																				)}
																		</div>
																	</Col>

																	<Col
																		lg={5}
																		md={8}
																	>
																		<div
																			className={
																				styles[
																					"industry-ct-content"
																				]
																			}
																		>
																			{point?.text && (
																				<TruncatedText
																					width="100%"
																					text={
																						point.text
																					}
																					activeTab={
																						activeIndex
																					}
																				/>
																			)}
																		</div>
																	</Col>

																	<Col
																		lg={4}
																		md={4}
																	>
																		{point
																			?.image
																			?.data
																			?.attributes
																			?.url && (
																			<div
																				className={
																					styles[
																						"industry-rt-img"
																					]
																				}
																			>
																				<Image
																					src={
																						point
																							?.image
																							?.data
																							?.attributes
																							?.url
																							? toWebp(
																									`${baseUrl}${point?.image?.data?.attributes?.url}`
																							  )
																							: null
																					}
																					alt={
																						point
																							?.image
																							?.data
																							?.attributes
																							?.name ||
																						"Technology"
																					}
																					height={
																						1200
																					}
																					width={
																						800
																					}
																					quality={
																						100
																					}
																				/>
																			</div>
																		)}
																	</Col>
																</Row>
															</div>
														</Col>
													</Row>
												</Tab.Pane>
											);
										})}
									<Tab.Pane
										eventKey={i}
										key={`${key?.points.length + 1}_j`}
									>
										{key.button && (
											<Row>
												<Col lg={12}>
													<div
														className="mt-3 view-all"
														style={{
															display: "flex",
															justifyContent:
																"center",
															alignItems:
																"center",
														}}
													>
														<Link
															href={
																key.button.link
																	.href
															}
															className="btn btn-primary"
														>
															{key.button.link
																.label ??
																"Schedule a Call"}
														</Link>
													</div>
												</Col>
											</Row>
										)}
									</Tab.Pane>
								</React.Fragment>
							);
						})}
				</Tab.Content>
			</Tab.Container>
		</div>
	);
};

export default IndustryWeb;
